<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">
        <span v-if="mData.viewMode == 'create'">{{aLang.c_create}} Bảng khảo sát</span>
        <div class="card-header-actions"></div>
      </div>
      <div class="card-body">
        <el-form
          :model="mData.dataForm"
          :rules="mData.validForm"
          ref="myForm"
          label-width="100%"
          label-position="top">
          <div class="card">
            <header class="card-header">Biểu mẫu</header>
            <div class="card-body">
              <b-row v-if="mData.viewMode == 'create'||mData.viewMode == 'update'" class="justify-content-center">
                <b-col md="5">
                  <el-form-item label="Tên biểu mẫu" prop="survey.name" clearable>
                    <el-input
                      v-model="mData.dataForm.survey.name"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col md="5">
                  <el-form-item label="Hệ thống" clearable prop="survey.applicationId">
                    <el-select v-model="mData.dataForm.survey.applicationId" default-first-option>
                      <el-option
                        v-for=" item in mData.dataDefault.listDataApplications"
                        :key="item.id"
                        :label=" item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </b-col>
              </b-row>

              <b-row class="justify-content-center">
                <b-col md="5">
                  <el-form-item label="Mô tả" prop="survey.description" clearable>
                    <el-input
                      type="textarea"
                      autosize
                      v-model="mData.dataForm.survey.description"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col md="5">
                  <el-form-item label="Thời điểm hết hạn gửi phản hồi (bỏ trống nghĩa là vô thời hạn)"
                                prop="survey.expriedDate">
                    <el-date-picker v-model="mData.dataForm.survey.expriedDate" type="date" format="dd/MM/yyyy"
                                    value-format="yyyy-MM-ddTHH:mm:ss" placeholder="Chọn ngày">
                    </el-date-picker>
                  </el-form-item>
                </b-col>
              </b-row>
              <b-row>

                <b-col md="12" class="text-center">
                  <el-button @click="fn_go()" type="info" plain>{{aLang.c_back}}</el-button>
                  <el-button
                    type="primary"
                    plain
                    @click="fn_submitSurvey()"
                    v-ladda
                    v-if="mData.viewMode == 'update'"
                  >{{aLang.c_update}}
                  </el-button>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="card mt-5 ">
            <header class="card-header">Câu hỏi</header>
            <div class="card-body pb-0">
              <b-row v-if="mData.viewMode == 'create' | mData.viewMode == 'update' ">
                <b-col md="12">
                  <el-form-item class="text-right mt-3">
                    <el-button
                      type="info"
                      plain
                      @click="fn_initSurveyItemCreate"
                      v-if="mData.viewMode == 'create' | mData.viewMode == 'update'"
                    >{{aLang.c_create}} câu hỏi
                    </el-button>
                  </el-form-item>
                </b-col>
              </b-row>
              <b-col md="12" class="padding-0 mr-0">
                <el-table
                  :data="mData.dataDefault.surveyItems"
                  border
                  stripe
                  class="text-left justify-content-center"
                  style="width: 100%"
                >
                  <el-table-column align="center"
                                   label="STT"
                                   type="index"
                                   width="100">
                  </el-table-column>
                  <el-table-column align="left"
                                   prop="code"
                                   label="Mã thẻ"
                                   width="180">
                  </el-table-column>
                  <el-table-column align="left"
                                   prop="inputControl"
                                   label="Đối tượng nhập liệu"
                                   width="180">
                  </el-table-column>
                  <el-table-column align="left"
                                   prop="title"
                                   label="Tiêu đề">
                  </el-table-column>
                  <el-table-column :label="aLang.c_action" align="center" width="250px"
                                   v-if="mData.viewMode == 'create' | mData.viewMode == 'update'">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        @click="fn_handleSurveyItem('delete',scope.$index)"
                        v-b-popover.hover.left="aLang.c_delete"
                      >
                        <i class="fa icon-trash"></i>
                      </el-button>
                      <el-button
                        size="mini"
                        @click="fn_handleSurveyItem('update',scope.$index)"
                        v-b-popover.hover.left="aLang.c_update"
                      >
                        <i class="icon-pencil icons"></i>
                      </el-button>
                      <el-button
                        size="mini"
                        @click="fn_handleUpItem('up',scope.$index, mData.dataDefault.surveyItems)"
                        v-b-popover.hover.left="'Up'"
                      >
                        <i class="icon-arrow-up-circle icons"></i>
                      </el-button>
                      <el-button
                        size="mini"
                        @click="fn_handleDownItem('down',scope.$index, mData.dataDefault.surveyItems)"
                        v-b-popover.hover.left="'Down'"
                      >
                        <i class="icon-arrow-down-circle icons"></i>
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </b-col>
              <el-form-item class="text-center mt-3">
                <el-button
                  type="info"
                  plain
                  @click="fn_submitSurvey()"
                  v-ladda
                  v-if="mData.viewMode == 'create'"
                >{{aLang.c_create}}
                </el-button>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <el-form
          :model="mData.dataForm"
          :rules="mData.validForm"
          ref="myFormSurveyItem"
        >
          <el-dialog
            title="Câu hỏi"
            :visible.sync="mData.dataDefault.visibleSurveyItemForm"
            width="80%"
            :before-close="fn_closeFormSurveyItem"
            :close-on-click-modal="false">
            <b-col md="12">
              <b-row>
                <!--                singleline-->
                <b-col md="3"
                       v-if="mData.dataForm.surveyItem.inputControl != 'label' || mData.dataForm.surveyItem.labelType != 'multipleline' && mData.dataForm.surveyItem.labelType != 'richtext'">
                  <el-form-item label="Tiêu đề hiển thị" prop="surveyItem.title" clearable>
                    <el-input
                      v-model="mData.dataForm.surveyItem.title"
                      clearable></el-input>
                  </el-form-item>
                </b-col>
                <!--                multipleline-->
                <b-col md="3"
                       v-if="mData.dataForm.surveyItem.inputControl == 'label' && mData.dataForm.surveyItem.labelType === 'multipleline'">
                  <el-form-item label="Tiêu đề hiển thị" prop="surveyItem.title" clearable>
                    <el-input
                      type="textarea"
                      v-model="mData.dataForm.surveyItem.title"
                      :autosize="{ minRows: 1}"
                      clearable></el-input>
                  </el-form-item>
                </b-col>
              </b-row>
              <b-row>
                <!--                customize-->
                <b-col md="12"
                       v-if="mData.dataForm.surveyItem.inputControl == 'label' && mData.dataForm.surveyItem.labelType === 'richtext'">
                  <el-form-item label="Tiêu đề hiển thị" prop="surveyItem.title" clearable>
                    <editor-component
                      :prop-data-editor="mData.dataForm.surveyItem"
                      :prop-data-editor-key="'title'"
                      :prop-data-editor-disabled="mData.viewMode == 'detail'"
                      :height="'200px'"
                    ></editor-component>
                  </el-form-item>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <el-form-item label="Mã" prop="surveyItem.code" clearable>
                    <el-input
                      v-model="mData.dataForm.surveyItem.code"
                      clearable
                      :disabled="mData.viewModeSurveyItem == 'update'"
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col md="3">
                  <el-form-item label="Giao diện hiển thị" prop="surveyItem.inputControl" clearable>
                    <el-select v-model="mData.dataForm.surveyItem.inputControl" clearable filterable
                               @change="fn_onChangeInputControl">
                      <el-option
                        v-for=" item in mData.dataDefault.inputControlOptions"
                        :key="item.value"
                        :label=" item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </b-col>
                <b-col md="3"
                       v-if="mData.dataDefault.enableDefaultValue.includes(mData.dataForm.surveyItem.inputControl)">
                  <el-form-item label="Giá trị mặc định" clearable>
                    <el-input
                      v-model="mData.dataForm.surveyItem.defaultValue"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col md="3">
                  <el-form-item label="Thứ tự hiển thị của item" prop="surveyItem.order" clearable>
                    <el-input
                      type="number"
                      v-model="mData.dataForm.surveyItem.order"
                      clearable
                      disabled
                    ></el-input>
                  </el-form-item>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" v-if="false">
                  <el-form-item label="Mô tả" prop="surveyItem.description" clearable>
                    <el-input
                      type="textarea"
                      v-model="mData.dataForm.surveyItem.description"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3" v-if="mData.dataForm.surveyItem.inputControl == 'textbox'">
                  <el-form-item label="Loại bàn phím" prop="surveyItem.textboxType">
                    <el-select v-model="mData.dataForm.surveyItem.textboxType" clearable filterable>
                      <el-option
                        v-for=" item in mData.dataDefault.dataTextboxTypes"
                        :key="item.value"
                        :label=" item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </b-col>
                <b-col md="3" v-if="mData.dataForm.surveyItem.inputControl == 'label'">
                  <el-form-item label="Loại hiển thị" prop="surveyItem.labelType">
                    <el-select v-model="mData.dataForm.surveyItem.labelType" clearable filterable>
                      <el-option
                        v-for=" item in mData.dataDefault.labelTypes"
                        :key="item.value"
                        :label=" item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </b-col>
                <b-col md="3" v-if="mData.dataForm.surveyItem.inputControl && !['hidden', 'label'].includes(mData.dataForm.surveyItem.inputControl)">
                  <el-form-item label="Trạng thái vô hiệu hóa" prop="surveyItem.order" clearable>
                    <el-select
                      v-model="mData.dataForm.surveyItem.isDisable"
                    >
                      <el-option
                        v-for=" item in mData.dataDefault.inputDisableOptions"
                        :key="item.value"
                        :label=" item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </b-col>
                <!--                hidden col when condition apply-->
                <b-col md="12"
                       v-if="mData.dataDefault.optionsInputControl.includes(mData.dataForm.surveyItem.inputControl)">
                  <div class="card ">
                    <div class="card-body">
                      <b-row>
                        <b-col md="3"
                               v-if="mData.dataForm.surveyItem.inputControl == 'selectbox'">
                          <el-form-item label="Chọn nhiều giá trị" clearable>
                            <el-checkbox
                              v-model="mData.dataForm.surveyItem.isMultipleValue"
                            ></el-checkbox>
                          </el-form-item>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="3">
                          <el-form-item label="Kiểu giá trị" prop="surveyItem.dataSource.type" clearable>
                            <el-select
                              v-model="mData.dataForm.surveyItem.dataSource.type"
                              clearable
                              placeholder="Giá trị"
                              @change="fn_onChangeDataTypeInputControl"
                              filterable
                            >
                              <el-option
                                v-for="(item,index) in mData.dataDefault.surveySelectboxTypeOption"
                                :key="index"
                                :label="item.label"
                                :value="item.value"
                                clearable>
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </b-col>
                        <b-col md="3"
                               v-if="mData.dataForm.surveyItem.dataSource && mData.dataForm.surveyItem.dataSource.type =='api'">
                          <el-form-item label="Cấu hình" prop="surveyItem.dataSource.requestApi.type">
                            <el-select
                              v-model="mData.dataForm.surveyItem.dataSource.requestApi.type"
                              placeholder="Giá trị"
                              filterable
                            >
                              <el-option
                                v-for="(item,index) in mData.dataDefault.apiTypes"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </b-col>
                        <b-col md="3"
                               v-if="mData.dataForm.surveyItem.dataSource && mData.dataForm.surveyItem.dataSource.type =='api' && mData.dataForm.surveyItem.dataSource.requestApi.type != 'system' ">
                          <el-form-item label="Đường dẫn" prop="surveyItem.dataSource.requestApi.url">
                            <el-input
                              type="text"
                              v-model="mData.dataForm.surveyItem.dataSource.requestApi.url"
                              clearable
                            ></el-input>
                          </el-form-item>
                        </b-col>
                        <b-col md="3"
                               v-if="mData.dataForm.surveyItem.dataSource && mData.dataForm.surveyItem.dataSource.type =='api' && mData.dataForm.surveyItem.dataSource.requestApi.type == 'system' ">
                          <el-form-item label="Danh sách API" prop="systemApiTemplate">
                            <el-select
                              v-model="mData.dataForm.systemApiTemplate"
                              placeholder="Giá trị"
                              filterable
                              @change="setSystemApiTemplate"
                            >
                              <el-option
                                v-for="item in mData.dataDefault.systemApiTemplates"
                                :label="item.name"
                                :value="item.id">
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </b-col>
                        <b-col md="12" v-if="mData.dataForm.surveyItem.dataSource && mData.dataForm.surveyItem.dataSource.type =='api' && mData.dataForm.surveyItem.dataSource.requestApi.type == 'system' ">
                          <b-row>
                            <b-col md="3" v-for="(selectbox, index) in mData.paramsList">
                              <el-form-item :label="selectbox.description" required :key="index">
                                <el-select
                                  v-model="mData.paramsSelect[selectbox.name]"
                                  placeholder="Giá trị"
                                  filterable
                                  @change="changeApiParams"
                                >
                                  <el-option v-for="surveyItem in fn_initDataSurveyItems()"
                                             :label="surveyItem.code + ' - ' + surveyItem.title"
                                             :value="surveyItem.code"
                                             :key="surveyItem.code"></el-option>
                                </el-select>
                              </el-form-item>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="3"
                               v-if=" mData.dataForm.surveyItem.dataSource && mData.dataForm.surveyItem.dataSource.type =='array'">
                          <el-form-item label="Nhập giá trị" prop="surveyItem.dataSource.value" clearable>
                            <el-input
                              v-model="mData.dataForm.surveyItem.dataSource.value"
                              type="textarea"
                              autosize
                              placeholder="Giá trị"
                              clearable>
                            </el-input>
                          </el-form-item>
                        </b-col>
                        <b-col md="3"
                               v-if="mData.dataForm.surveyItem.dataSource && mData.dataForm.surveyItem.dataSource.type =='keyValue'">
                          <el-form-item label="Nhập giá trị key-value"
                                        :prop="fn_IsEmptyArray(mData.listSurveyItemValueTypeKeyValue)?'surveyItemValueTypeKeyValue':''"
                                        clearable>
                            <el-input
                              v-model="mData.dataForm.surveyItemValueTypeKeyValue"
                              type="textarea"
                              autosize
                              placeholder="Giá trị"
                              @input="fn_OnchangeKeyOrValue"
                              clearable>
                            </el-input>
                          </el-form-item>
                        </b-col>
                        <b-col md="6"
                               v-if="mData.dataForm.surveyItem.dataSource && mData.dataForm.surveyItem.dataSource.type =='keyValue'"
                        >
                          <el-table
                            :data="mData.listSurveyItemValueTypeKeyValue"
                            border
                            stripe
                            class="text-left justify-content-center"
                            style="width: 100%"
                          >
                            <el-table-column align="center"
                                             label="STT"
                                             type="index"
                                             width="80">
                            </el-table-column>
                            <el-table-column align="left"
                                             prop="key"
                                             label="Key"
                                             width="153">
                            </el-table-column>
                            <el-table-column align="left"
                                             prop="value"
                                             label="Value"
                                             width="auto">
                            </el-table-column>
                            <el-table-column :label="aLang.c_action" align="center" width="250px"
                                             v-if="mData.viewMode == 'create' | mData.viewMode == 'update'">
                              <template slot-scope="scope">
                                <el-button
                                  size="mini"
                                  @click="fn_MKeyValue('delete',scope.$index)"
                                  v-b-popover.hover.left="aLang.c_delete"
                                >
                                  <i class="fa icon-trash"></i>
                                </el-button>
                              </template>
                            </el-table-column>
                          </el-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12"
                               v-if="mData.dataForm.surveyItem.dataSource && mData.dataForm.surveyItem.dataSource.type =='api'
                                     && mData.dataForm.surveyItem.dataSource.requestApi.type=='customize'">
                          <el-table :data="mData.dataDefault.requestApiData" style="width: 100%">
                            <el-table-column label="Type">
                              <template slot-scope="scope">
                                {{scope.row.label}}
                              </template>
                            </el-table-column>
                            <el-table-column label="Value">
                              <template slot-scope="scope">
                                <el-select v-if="scope.row.code == 'method'" v-model="scope.row.value">
                                  <el-option v-for="methodItem in fn_initDataMethods()"
                                             :label="methodItem"
                                             :value="methodItem"
                                             :key="methodItem">
                                  </el-option>
                                </el-select>
                                <v-jsoneditor
                                  v-if="scope.row.code == 'headers' || scope.row.code == 'body' || scope.row.code =='mappingFields'"
                                  v-model="scope.row.value"
                                  :plus="true"
                                  :height="'100px'">
                                </v-jsoneditor>
                                <el-select v-if="scope.row.code == 'surveyItemCode'" v-model="scope.row.value" multiple
                                           clearable>
                                  <el-option v-for="surveyItem in fn_initDataSurveyItems()"
                                             :label="surveyItem.code + ' - ' + surveyItem.title"
                                             :value="surveyItem.code"
                                             :key="surveyItem.code">
                                  </el-option>
                                </el-select>
                              </template>
                            </el-table-column>
                          </el-table>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
                <b-col v-if="mData.dataForm.surveyItem.inputControl == 'image'">
                  <el-form-item label="Tải ảnh lên">
                    <el-upload
                      action
                      :auto-upload="false"
                      :class="[mData.imageUploads.fileList.length > 0 ? 'hidden-icon-upload' : '']"
                      :accept="mData.imageUploads.accept"
                      :on-change="fn_onChangeImage"
                      :on-preview="fn_onPreviewImage"
                      :on-remove="fn_onRemoveImage"
                      :on-exceed="fn_onExceedImage"
                      :limit="mData.imageUploads.limit"
                      :file-list="mData.imageUploads.fileList"
                      list-type="picture-card">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog
                      :close-on-click-modal="true"
                      :visible.sync="mData.imageUploads.dialogVisible"
                      width="60%"
                      top="51px"
                      title="Chi tiết"
                    >
                      <img
                        width="100%"
                        :src="mData.imageUploads.dialogImageUrl"
                        alt
                      />
                    </el-dialog>
                  </el-form-item>
                </b-col>
              </b-row>


              <!--rang buoc du lieu-->
              <b-row
                v-if="mData.dataForm.surveyItem.inputControl != 'label' && mData.dataForm.surveyItem.inputControl != 'image' && mData.dataForm.surveyItem.inputControl != 'hidden'">
                <b-col>
                  <el-form-item label="Ràng buộc dữ liệu" class="text-center"/>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="2">
                  <el-form-item label=" " prop="surveyItem.validation.required" clearable
                                v-if="mData.dataForm.surveyItem.inputControl != 'label' && mData.dataForm.surveyItem.inputControl != 'image'&& mData.dataForm.surveyItem.inputControl != 'hidden'">
                    <el-checkbox v-model="mData.dataForm.surveyItem.validation.required">Bắt buộc nhập</el-checkbox>
                  </el-form-item>
                </b-col>
                <b-col md="2">
                  <el-form-item label="  " clearable
                                v-if="mData.dataForm.surveyItem.inputControl != 'hidden'">
                    <el-checkbox v-model="mData.dataDefault.isHasCondition">Điều kiện hiển thị</el-checkbox>
                  </el-form-item>
                </b-col>
              </b-row>
              <b-row v-if="mData.dataDefault.isHasCondition">
                <b-col md="12">
                  <div class="card">
                    <div class="card-body">
                      <b-row>
                        <b-col md="3">
                          <el-form-item label="Tên cột" prop="surveyItem.condition" clearable>
                            <el-select v-model="mData.dataDefault.surveyItemCodeCondition"
                                       clearable>
                              <el-option v-for="surveyItem in fn_initDataSurveyItems()"
                                         :label="surveyItem.code + ' - ' + surveyItem.title"
                                         :value="surveyItem.code"
                                         :key="surveyItem.code">
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </b-col>
                        <b-col md="3">
                          <el-form-item label="Giá trị" prop="surveyItem.condition" clearable>
                            <el-input v-model="mData.dataDefault.surveyItemConditionValue"></el-input>
                          </el-form-item>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="4">
                  <el-form-item label="Giá trị tối thiểu" prop="surveyItem.validation.min" clearable
                                v-if="mData.dataForm.surveyItem.inputControl == 'number'">
                    <el-input
                      type="number"
                      v-model="mData.dataForm.surveyItem.validation.min"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col md="4">
                  <el-form-item label="Giá trị tối đa" prop="surveyItem.validation.max" clearable
                                v-if="mData.dataForm.surveyItem.inputControl == 'number'">
                    <el-input
                      type="number"
                      v-model="mData.dataForm.surveyItem.validation.max"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
              </b-row>
              <b-row
                v-if="mData.dataForm.surveyItem.inputControl =='textbox' || mData.dataForm.surveyItem.inputControl =='email'"
                class="justify-content-center">
                <b-col md="4">
                  <el-form-item label="Độ dài chuỗi" prop="surveyItem.validation.length" clearable>
                    <el-input
                      type="number"
                      v-model="mData.dataForm.surveyItem.validation.length"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col md="4">
                  <el-form-item label="Độ dài tối thiểu của chuỗi" prop="surveyItem.validation.minLength"
                                clearable>
                    <el-input
                      type="number"
                      v-model="mData.dataForm.surveyItem.validation.minLength"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col md="4">
                  <el-form-item label="Độ dài tối đa của chuỗi" prop="surveyItem.validation.maxLength"
                                clearable>
                    <el-input
                      type="number"
                      v-model="mData.dataForm.surveyItem.validation.maxLength"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4"
                       v-if=" mData.dataForm.surveyItem.inputControl =='textbox' || mData.dataForm.surveyItem.inputControl =='email'">
                  <el-form-item label="Định dạng chuỗi" prop="surveyItem.validation.pattern" clearable>
                    <el-input
                      type="text"
                      v-model="mData.dataForm.surveyItem.validation.pattern"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col md="4"
                       v-if=" mData.dataForm.surveyItem.inputControl =='textbox' || mData.dataForm.surveyItem.inputControl =='email'">
                  <el-form-item label="Thông báo sai định dạng"
                                prop="surveyItem.validation.patternMessage" clearable>
                    <el-input
                      type="textarea"
                      autosize
                      v-model="mData.dataForm.surveyItem.validation.patternMessage"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
              </b-row>
            </b-col>
            <span slot="footer" class="dialog-footer">
            <el-button @click="fn_closeFormSurveyItem">Đóng</el-button>
            <el-button type="primary" @click="fn_submitSurveyItem">{{this.mData.viewModeSurveyItem == "create"?'Tạo câu hỏi':'Cập nhật câu hỏi'}}</el-button>
          </span>
          </el-dialog>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import vue from 'vue';
  import LanguageService from "../../../service/LanguageService";
  import MethodService from "../../../service/MethodService";
  import SurveyModel from "./SurveyModel";
  import MapComponent from "../../../component/MapComponent";
  import SurveyApi from "../../../moduleApi/SurveyApi"
  import SurveyItemApi from "../../../moduleApi/SurveyItemApi";
  import SurveyResultApi from "../../../moduleApi/SurveyResultApi";
  import ApplicationApi from "../../../moduleApi/ApplicationApi";
  import EditorComponent from "../../../component/EditorComponent";
  import ExtensionService from "../../../service/ExtensionService";
  import VueJsonPretty from "vue-json-pretty";
  import VJsoneditor from 'v-jsoneditor/src/index';
  import _ from 'lodash'

  vue.use(VJsoneditor)
  export default {
    components: {
      MapComponent,
      EditorComponent,
      VueJsonPretty
    },
    data() {
      return {
        aLang: LanguageService.lang,
        mData: {
          viewMode: "create",
          viewModeSurveyItem: "create",
          moduleName: "Survey",
          dataDefault: {
            visibleSurveyItemForm: false,
            surveyItems: [],
            listDataApplications: [],
            surveyItemIndex: null,
            surveyId: null,
            apiTypes: [
              {value: "system", label: "Hệ thống"},
              {value: "basic", label: "Cơ bản"},
              {value: "customize", label: "Tùy chỉnh"},
            ],
            inputControlOptions: [
              {value: 'textbox', label: 'Textbox'},
              {value: 'selectbox', label: 'Selectbox'},
              {value: 'checkbox', label: 'Checkbox'},
              {value: 'radio', label: 'Radio'},
              {value: 'email', label: 'Email'},
              {value: 'number', label: 'Number'},
              {value: 'label', label: 'Label'},
              {value: 'image', label: 'Image'},
              {value: 'date', label: 'Date'},
              {value: 'datetime', label: 'Datetime'},
              {value: 'file', label: 'Upload file'},
              {value: 'map', label: 'Bản đồ'},
              {value: 'hidden', label: 'Hidden'}
            ],
            dataTextboxTypes: [
              {value: 'number', label: 'Số'},
              {value: 'text', label: 'Chuỗi ký tự'}
            ],
            labelTypes: [
              {value: 'singleline', label: 'Một dòng'},
              {value: 'multipleline', label: 'Nhiều dòng'},
              {value: 'richtext', label: 'Tùy chỉnh'}
            ],
            requestApiData: [
              {code: 'surveyItemCode', label: 'Điều kiện lọc phụ thuộc vào trường', value: []},
              {code: 'method', label: 'Method', value: "GET"},
              {code: 'headers', label: 'Headers', value: []},
              {code: 'body', label: 'Body', value: {}},
              {code: 'mappingFields', label: 'Map trường dữ liệu', value: {'key': 'key', 'value': 'value'}}
            ],
            surveySelectboxTypeOption: [
              {value: 'array', label: 'Mảng'},
              {value: 'keyValue', label: 'Key - Value'},
              {value: 'api', label: 'API'}
            ],
            optionsInputControl: ['selectbox', 'checkbox', 'radio'],
            enableDefaultValue: ['textbox', 'selectbox', 'checkbox', 'radio', 'email', 'number', 'date', 'datetime'],
            methods: ["GET", "POST", "PUT", "HEAD", "DELETE", "PATCH", "OPTIONS"],
            method: 'GET',
            isHasCondition: false,
            surveyItemCodeCondition: null,
            surveyItemConditionValue: null,
            inputDisableOptions: [
              {value: true, label: 'Có'},
              {value: false, label: 'Không'},
            ],
            systemApiTemplates: []
          },
          imageUploads: {
            limit: 1,
            accept: ".jpg, .png, .jpeg",
            dialogImageUrl: "",
            dialogVisible: false,
            fileList: []
          },
          dataForm: MethodService.copyObject(SurveyModel.dataForm),
          validForm: SurveyModel.validForm,
          listSurveyItemValueTypeKeyValue: [],
          paramsList: [],
          paramsSelect: {}
        },
      };
    },
    methods: {
      //file image handle
      fn_onChangeImage(file, fileList) {
        ExtensionService.upload(file, "survey/upload")
          .then(res => {
            this.mData.dataForm.surveyItem.fileId = res.result;
            this.mData.imageUploads.fileList = fileList;
          })
          .catch(error => {
            $.each(fileList, (i, el) => {
              if (el.uid == file.uid) {
                fileList.splice(i, 1);
              }
            });
          });
      },
      fn_closeFormSurveyItem() {
        this.mData.dataDefault.isHasCondition = false;
        this.mData.dataDefault.visibleSurveyItemForm = false;
        this.mData.dataDefault.surveyItemConditionValue = null;
        this.mData.dataDefault.surveyItemCodeCondition = null;
      },
      fn_onExceedImage(files, fileList) {
        toastr.info(
          `Chỉ tải lên tối đa ${this.mData.imageUploads.limit} file`
        );
      },
      fn_onPreviewImage(file) {
        this.mData.imageUploads.dialogImageUrl = file.url;
        this.mData.imageUploads.dialogVisible = true;
      },
      fn_onRemoveImage(file, fileList) {
        this.mData.dataForm.surveyItem.fileId = null;
        this.mData.imageUploads.fileList = [];
      },

      //init Data
      fn_initDataMethods() {
        return this.mData.dataDefault.methods;
      },
      fn_initDataSurveyItems() {
        return this.mData.dataDefault.surveyItems
      },

      //reset Data
      fn_refreshData() {
        this.mData.dataForm.surveyItem.labelType = MethodService.copyObject(SurveyModel.dataForm.surveyItem.labelType);
        this.mData.dataForm.surveyItem.textboxType = MethodService.copyObject(SurveyModel.dataForm.surveyItem.textboxType);
        this.mData.dataForm.surveyItem.fileId = MethodService.copyObject(SurveyModel.dataForm.surveyItem.fileId);
        this.mData.dataForm.surveyItem.isMultipleValue = MethodService.copyObject(SurveyModel.dataForm.surveyItem.isMultipleValue);
        this.mData.dataForm.surveyItem.dataSource = MethodService.copyObject(SurveyModel.dataForm.surveyItem.dataSource);
        this.mData.imageUploads.fileList = [];
      },
      //up-down order survey item
      async fn_handleUpItem(type, index, dataTable) {
        if (index == 0) {
          toastr.error('Bản ghi đang ở vị trí đầu tiên');
          return;
        } else {
          let surveyItemUp = dataTable[index];
          let surveyItemDown = dataTable[index - 1];
          surveyItemUp.order = surveyItemUp.order - 1;
          surveyItemDown.order = surveyItemDown.order + 1;
          await SurveyItemApi.update(surveyItemUp);
          await SurveyItemApi.update(surveyItemDown);
          this.fn_refreshSurveyItems();
        }
      },
      async fn_refreshSurveyItems() {
        let resSurveyItems = await SurveyItemApi.list({
          filters: "surveyId=" + this.$router.currentRoute.params.id,
          limit: 1000
        });
        this.mData.dataDefault.surveyItems = await resSurveyItems.result;
      },
      async fn_handleDownItem(type, index, dataTable) {
        if (index == dataTable.length - 1) {
          toastr.error('Bản ghi đang ở vị trí cuối cùng');
          return;
        } else {
          let surveyItemDown = dataTable[index];
          let surveyItemUp = dataTable[index + 1];
          surveyItemDown.order = surveyItemDown.order + 1;
          surveyItemUp.order = surveyItemUp.order - 1;
          await SurveyItemApi.update(surveyItemUp);
          await SurveyItemApi.update(surveyItemDown);
          this.fn_refreshSurveyItems();
        }
      },

      //reset data when change input control
      fn_onChangeInputControl() {
        this.fn_refreshData();
        let inputControl = this.mData.dataForm.surveyItem.inputControl;
        switch (inputControl) {
          case 'checkbox':
            this.mData.dataForm.surveyItem.isMultipleValue = true;
            break;
        }
      },
      fn_onChangeDataTypeInputControl() {
        this.mData.listSurveyItemValueTypeKeyValue.splice(0, this.mData.listSurveyItemValueTypeKeyValue.length);
        this.mData.dataForm.surveyItemValueTypeKeyValue = null;
        if (this.mData.dataForm.surveyItem.dataSource.type == 'api') {
          this.mData.dataForm.surveyItem.dataSource.requestApi.method = this.mData.dataDefault.methods[0];
        }
      },
      fn_OnchangeKeyOrValue: _.debounce(function (e) {
        try {
          this.mData.listSurveyItemValueTypeKeyValue.splice(0, this.mData.listSurveyItemValueTypeKeyValue.length);
          let listSurveyItemValueTypeKeyValue = MethodService.copyObject(this.mData.listSurveyItemValueTypeKeyValue);
          let surveyItemValueTypeKeyValue = MethodService.copyObject(this.mData.dataForm.surveyItemValueTypeKeyValue);
          let listKeyValue = surveyItemValueTypeKeyValue.split("\n");
          let listKeyValueTemp = [];
          let listKey = [];
          let listValue = [];
          listKeyValue.map(item => {
            listKeyValueTemp.push(item.split(":"));
          });
          for (let i in listKeyValueTemp) {
            listKey.push(listKeyValueTemp[i][0].trim());
            listValue.push(listKeyValueTemp[i][1].trim());
          }
          for (let i in listValue) {
            listSurveyItemValueTypeKeyValue.push({
              key: listKey[i],
              value: listValue[i]
            })
          }
          this.mData.listSurveyItemValueTypeKeyValue = listSurveyItemValueTypeKeyValue;
        } catch (e) {
          console.log('error: ', e);
        }
      }, 500),
      fn_resetForm() {
        this.$refs["myForm"].resetFields();
        this.mData.dataDefault.surveyItems = [];
      },
      fn_go() {
        this.$router.go(-1);
      },
      fn_initSurveyItemCreate() {
        this.mData.viewModeSurveyItem = "create";
        this.mData.dataForm.surveyItem = MethodService.copyObject(SurveyModel.dataForm.surveyItem);
        if (this.mData.dataDefault.surveyId) {
          this.mData.dataForm.surveyItem.surveyId = this.mData.dataDefault.surveyId;
        }
        this.mData.dataForm.surveyItem.order = this.mData.dataDefault.surveyItems.length + 1;
        this.mData.dataDefault.visibleSurveyItemForm = true;
      },
      fn_getSurveyItemValueTypeArray(valueOrigin) {
        let listKey = Object.keys(valueOrigin);
        let valueBeforeParse = '';
        for (let i = 0; i < listKey.length; i++) {
          if (valueOrigin.hasOwnProperty(listKey[i])) {
            valueBeforeParse += valueOrigin[listKey[i]];
            if (i !== listKey.length - 1) {
              valueBeforeParse += '\n';
            }
          }
        }
        return valueBeforeParse;
      },
      fn_getSurveyItemValueTypeKeyValue(valueOrigin) {
        let listKey = Object.keys(valueOrigin);
        let valueBeforeParse = '';
        for (let i = 0; i < listKey.length; i++) {
          if (valueOrigin.hasOwnProperty(listKey[i])) {
            valueBeforeParse += listKey[i] + ':' + valueOrigin[listKey[i]];
            if (i !== (listKey.length - 1)) {
              valueBeforeParse += '\n';
            }
          }
        }
        return valueBeforeParse;
      },
      fn_parseDataSurveyItem(surveyItem) {
        Object.keys(SurveyModel.dataForm.surveyItem).forEach(function (key) {
          if (!surveyItem.hasOwnProperty(key) || (surveyItem.hasOwnProperty(key) && !surveyItem[key])) {
            surveyItem[key] = MethodService.copyObject(SurveyModel.dataForm.surveyItem[key]);
          } else if (key === 'dataSource' && !surveyItem[key].requestApi) {
            surveyItem[key].requestApi = MethodService.copyObject(SurveyModel.dataForm.surveyItem[key].requestApi);
          }
        });
        if (this.mData.dataDefault.optionsInputControl.includes(surveyItem.inputControl)) {
          if (surveyItem.dataSource && surveyItem.dataSource.value && surveyItem.dataSource.type && surveyItem.dataSource.type != 'api') {
            let valueOrigin = JSON.parse(surveyItem.dataSource.value);
            if (surveyItem.dataSource.type == 'keyValue') {
              surveyItem.dataSource.value = this.fn_getSurveyItemValueTypeKeyValue(valueOrigin);
              this.mData.dataForm.surveyItemValueTypeKeyValue = surveyItem.dataSource.value;
              this.fn_OnchangeKeyOrValue();
            } else {
              surveyItem.dataSource.value = this.fn_getSurveyItemValueTypeArray(valueOrigin);
            }
          }
          if (surveyItem.dataSource && surveyItem.dataSource.type &&
            surveyItem.dataSource.type == 'api' && surveyItem.dataSource.requestApi.type == 'customize') {
            this.mData.dataDefault.requestApiData.forEach(item => {
              if (item.code == 'body') {
                item.value = JSON.parse(surveyItem.dataSource.requestApi[item.code]);
              } else {
                item.value = surveyItem.dataSource.requestApi[item.code];
              }
            });
          }
        }
        return surveyItem;
      },
      fn_handleSurveyItem: function (type, index) {
        this.mData.imageUploads.fileList = [];
        this.mData.dataDefault.surveyItemIndex = index;
        var _this = this;
        if (type == "update") {
          let surveyItem = MethodService.copyObject(this.mData.dataDefault.surveyItems[index]);
          this.mData.dataForm.surveyItem = MethodService.copyObject(this.fn_parseDataSurveyItem(surveyItem));
          this.mData.dataDefault.visibleSurveyItemForm = true;
          this.mData.viewModeSurveyItem = type;
          if (surveyItem.condition && !MethodService.isNull(surveyItem.condition)) {
            _this.mData.dataDefault.isHasCondition = true;
            let condition = surveyItem.condition;
            let operatorDefaultValue = '==';
            _this.mData.dataDefault.surveyItemCodeCondition = condition.substring(condition.indexOf('{') + 1, condition.indexOf('}'));
            _this.mData.dataDefault.surveyItemConditionValue = condition.substring(condition.indexOf(operatorDefaultValue) + operatorDefaultValue.length, condition.length);
          }
        } else if (type == "delete") {
          this.mData.dataForm.surveyItem = this.mData.dataDefault.surveyItems[index];
          this.$confirm(LanguageService.lang.c_deleteConfirm, {
            confirmButtonText: LanguageService.lang.c_agree,
            cancelButtonText: LanguageService.lang.c_cancel,
            type: "warning"
          }).then(async () => {
            if (_this.mData.viewMode == 'update') {
              await SurveyItemApi.delete(_this.mData.dataForm.surveyItem.id);
            }
            _this.mData.dataDefault.surveyItems.splice(index, 1);
          })
            .catch(() => {
            });

        }
      },
      fn_handleDataRequestApi() {
        let requestApi = MethodService.copyObject(this.mData.dataForm.surveyItem.dataSource.requestApi);
        if (this.mData.dataForm.surveyItem.dataSource.requestApi.type == 'customize') {
          this.mData.dataDefault.requestApiData.forEach(item => {
            if (item.code == 'body') {
              requestApi[item.code] = JSON.stringify(item.value);
            } else {
              requestApi[item.code] = item.value;
            }
          });
        }
        return requestApi;
      },
      fn_parseDataValue(surveyItemValueOrigin, type) {
        if (type == 'array') {
          let valueArrayTypeGeojson = {};
          let surveyItemValueArray = surveyItemValueOrigin.split('\n');
          surveyItemValueArray.map(item => {
            valueArrayTypeGeojson[item] = item;
          });
          return JSON.stringify(valueArrayTypeGeojson).toString();
        }
        if (type == 'keyValue') {
          let valueArrayTypeGeojson = {};
          surveyItemValueOrigin.map(item => {
            valueArrayTypeGeojson[item.key] = item.value;
          });
          return JSON.stringify(valueArrayTypeGeojson).toString();
        }
        return '';
      },
      fn_submitSurvey() {
        this.$refs["myForm"].validate(async valid => {
          if (valid) {
            if (this.mData.viewMode == 'create') {
              let dataForm = MethodService.copyObject(this.mData.dataForm);
              let surveyResult = await SurveyApi.create(dataForm.survey);
              if (surveyResult.code === 0) {
                let surveyId = await surveyResult.result;
                this.mData.dataDefault.surveyItems.forEach((item, index) => {
                  item.surveyId = surveyId;
                });
                let surveyItemResult = await SurveyItemApi.create(this.mData.dataDefault.surveyItems);
                if (surveyItemResult.code === 0) {
                  this.fn_resetForm();
                }
              }
            }
            if (this.mData.viewMode == 'update') {
              let dataForm = MethodService.copyObject(this.mData.dataForm);
              await SurveyApi.update(dataForm.survey);
              this.fn_go();
            }
          } else {
            MethodService.stopLadda();
            return false;
          }
        });

      },
      fn_submitSurveyItem() {
        this.$refs["myFormSurveyItem"].validate(async valid => {
          if (valid) {
            let isExists = false;
            let surveyItem = MethodService.copyObject(this.mData.dataForm.surveyItem);
            this.mData.dataDefault.surveyItems.forEach((item, index) => {
              if ((this.mData.dataDefault.surveyItemIndex != null
                && index != this.mData.dataDefault.surveyItemIndex
                && item.code == surveyItem.code)
                || (item.code == surveyItem.code && this.mData.dataDefault.surveyItemIndex == null)) {
                isExists = true;
              }
            });

            if (isExists) {
              toastr.error('Thẻ đã tồn tại');
              return;
            }
            if (this.mData.dataDefault.optionsInputControl.includes(surveyItem.inputControl)
              && surveyItem.dataSource && surveyItem.dataSource.type) {

              if (surveyItem.dataSource.type != 'api') {
                if (surveyItem.dataSource.value || this.mData.listSurveyItemValueTypeKeyValue) {
                  surveyItem.dataSource.value = surveyItem.dataSource.type == 'array' ?
                    this.fn_parseDataValue(surveyItem.dataSource.value, surveyItem.dataSource.type) :
                    this.fn_parseDataValue(this.mData.listSurveyItemValueTypeKeyValue, surveyItem.dataSource.type);
                }
                if (MethodService.isEmptyObject(JSON.parse(surveyItem.dataSource.value))) {
                  toastr.error('Dữ liệu không chính xác!');
                  return;
                }
              } else if (surveyItem.dataSource.type == 'api' && surveyItem.dataSource.requestApi) {
                surveyItem.dataSource.requestApi = this.fn_handleDataRequestApi();
                if (!MethodService.hasChildObject(surveyItem.dataSource.requestApi)) {
                  toastr.error('Dữ liệu không chính xác!');
                  return;
                }
              }
            } else {
              surveyItem.dataSource = this.mData.viewModeSurveyItem == "create" ? undefined : null;
            }

            if (this.mData.dataDefault.isHasCondition && this.mData.dataDefault.surveyItemCodeCondition) {
              surveyItem.condition = '{' + this.mData.dataDefault.surveyItemCodeCondition + '}==' + (this.mData.dataDefault.surveyItemConditionValue ? this.mData.dataDefault.surveyItemConditionValue : '');
            } else {
              surveyItem.condition = null;
            }
            //remove all empty item
            this.fn_removeEmptyValueJson(surveyItem);
            if (this.mData.viewModeSurveyItem == "create") {
              if (this.mData.viewMode == "update") {
                let res = await SurveyItemApi.create(surveyItem);
                surveyItem.id = res.result;
              }
              this.mData.dataDefault.surveyItems.push(surveyItem);
            } else if (this.mData.viewModeSurveyItem == "update") {
              this.mData.dataDefault.surveyItems[this.mData.dataDefault.surveyItemIndex] = MethodService.copyObject(surveyItem);
              if (this.mData.viewMode == "update") {
                await SurveyItemApi.update(surveyItem);
              }
            }
            this.fn_closeFormSurveyItem();
          }
        });
      },
      async fn_initDataSurvey(surveyId) {
        let resSurvey = await SurveyApi.findById(surveyId);
        this.mData.dataForm.survey = resSurvey.result;
        let resSurveyItems = await SurveyItemApi.list({filters: "surveyId=" + surveyId, limit: 1000});
        this.mData.dataDefault.surveyItems = resSurveyItems.result;
      },
      async fn_initDataDefault() {
        let resApp = await ApplicationApi.list();
        this.mData.dataDefault.listDataApplications = resApp.result;
      },
      fn_IsEmptyArray(array) {
        return MethodService.isEmptyArray(array);
      },
      fn_removeEmptyValueJson(obj) {
        let _this = this;
        $.each(obj, function (key, value) {
          if (value === "" || value === null) {
            obj[key] = _this.mData.viewModeSurveyItem == "create" ? undefined : null;
          } else if (MethodService.isObject(value)) {
            if (!MethodService.hasChildObject(obj[key]))
              obj[key] = _this.mData.viewModeSurveyItem == "create" ? undefined : null;
            else
              _this.fn_removeEmptyValueJson(value);
          } else if (MethodService.isArray(value)) {
            $.each(value, function (k, v) {
              if (MethodService.isObject(v))
                _this.fn_removeEmptyValueJson(v);
            });
          }
        });
      },
      async callApiTemplate() {
        let res = await SurveyResultApi.getDataFromUrl(MyConfig.surveyApiTemplateUrl, 'GET');
        this.mData.dataDefault.systemApiTemplates = res.result;
      },
      setSystemApiTemplate() {
        let apiTemplate = this.mData.dataDefault.systemApiTemplates.find(x => x.id == this.mData.dataForm.systemApiTemplate);
        if (apiTemplate.params && apiTemplate.params.length > 0) {
          this.mData.paramsList = apiTemplate.params;
          this.mData.paramsList.forEach(item => {
            this.mData.paramsSelect[item.name] = null;
          })
        } else {
          this.mData.paramsList = [];
        }
        this.mData.dataForm.surveyItem.dataSource.requestApi = {
          body: apiTemplate.body ? apiTemplate.body : '{}',
          headers: apiTemplate.header ? apiTemplate.header : [],
          mappingFields: { key: apiTemplate.key, value: apiTemplate.value },
          method: apiTemplate.method,
          surveyItemCode: [],
          type: "system",
          url: apiTemplate.root + this.queryParamsBuilder()
        };
      },
      queryParamsBuilder() {
        if (this.mData.paramsSelect && Object.keys(this.mData.paramsSelect).length > 0) {
          let templateParamsList = [];
          for (const prop in this.mData.paramsSelect) {
            templateParamsList.push(`${prop}={${this.mData.paramsSelect[prop]}}`);
          }
          return '?' + templateParamsList.join('&');
        }
        return '';
      },
      changeApiParams() {
        let apiTemplate = this.mData.dataDefault.systemApiTemplates.find(x => x.id == this.mData.dataForm.systemApiTemplate);
        this.mData.dataForm.surveyItem.dataSource.requestApi.url = apiTemplate.root + this.queryParamsBuilder();
        this.mData.dataForm.surveyItem.dataSource.requestApi.surveyItemCode = [];
        apiTemplate.params.forEach(prop => {
          if (this.mData.paramsSelect[prop.name]) {
            this.mData.dataForm.surveyItem.dataSource.requestApi.surveyItemCode.push(this.mData.paramsSelect[prop.name]);
          }
        })
        this.mData.dataForm.surveyItem.dataSource.requestApi.surveyItemCode = [...new Set(this.mData.dataForm.surveyItem.dataSource.requestApi.surveyItemCode)]
        this.$forceUpdate();
      }
    },
    created: function () {
      this.callApiTemplate();
      this.fn_initDataDefault();
      if (
        this.$router.currentRoute.name ==
        "Admin-" + this.mData.moduleName + "-update"
      ) {
        this.mData.viewMode = "update";
      }
      if (this.$router.currentRoute.params.id) {
        this.mData.dataDefault.surveyId = this.$router.currentRoute.params.id;
        this.fn_initDataSurvey(this.mData.dataDefault.surveyId);
      }
    },
    mounted: function () {
    },
  }
</script>
